import React from 'react';
import PropTypes from 'prop-types';
import WindowContext from '../../context/WindowContext';
import { qualifyUrlInWindow } from '../../../utils/url';
import styles from './career-landing.scss';

function setClasses(name) {
    const iconName = 'icon-' + name;

    const classes = [
        styles.socialIcon,
        'icon',
        iconName,
        'white',
        'light-blue-hover',
    ];

    return classes.join('  ');
}

function CareersSocial({ header, social_links }) {
    const marginClass = header ? 'u-margin-top-med' : 0;

    return (
        <React.Fragment>
            <div className={`${styles.socialHeader}  light-blue  u-text-center`}>{header}</div>

            <div className={`${marginClass}  u-text-center`}>
                <WindowContext.Consumer>
                    {window => (
                        social_links.filter(item => item.url !== '')
                            .map((social_link, idx) => (
                                <a key={idx} href={qualifyUrlInWindow(social_link.url, window)} aria-label={social_link.name} className={setClasses(social_link.name)} />
                            ))
                    )}
                </WindowContext.Consumer>
            </div>
        </React.Fragment>
    );
}

CareersSocial.propTypes = {
    header: PropTypes.string,
    social_links: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        url: PropTypes.string,
    })),
};

CareersSocial.defaultProps = {
    header: '',
    social_links: [],
};

export default CareersSocial;
