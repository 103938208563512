import React from 'react';
import PropTypes from 'prop-types';
import { withDefaultLayoutAndGlobals } from '../layouts/TemplateBase.jsx';
import ConditionalRender from '../elements/ConditionalRender.jsx';
import CareersSocial from './career_landing/CareersSocial.jsx';
import Jumbotron from '../elements/Jumbotron.jsx';
import Nav from '../elements/Nav/index.jsx';
import PageEyebrow from '../elements/PageEyebrow.jsx';
import PlainHeader from '../elements/PlainHeader/index.jsx';
import Repeater from '../elements/Repeater.jsx';
import Carousel from '../elements/Carousel/index.jsx';
import styles from './career_landing/career-landing.scss';

class CareerLanding extends React.Component { // eslint-disable-line react/prefer-stateless-function
    static getRepeaterPropsForType(type) {
        const repeaterTypeProps = {
            captioned_media: {
                extraClasses: [
                    'bg-off-white',
                ],
            },
            content: {
                extraClasses: [
                    `${styles.mainContent}  rte  dark-blue  u-padding-top-med`,
                ],
            },
        };

        return repeaterTypeProps[type] || {};
    }

    render() {
        const {
            props,
        } = this;

        const { carousel } = props;
        return (
            <React.Fragment>
                <PlainHeader
                    color="medium-blue"
                    {...props.headerProps}
                />
                <Nav {...props.topNav} window={props.window} />

                <div className="l-page-container  bg-white">
                    <PageEyebrow
                        utilityNavProps={props.utilityNavProps}
                        hidePrint
                        hidePDF
                    />
                </div>

                <main className="l-standard-width  bg-white  u-padding-bottom-med">
                    { carousel && carousel.length > 0 &&
                        <div className="u-margin-bottom-large">
                            <Carousel slides={carousel} />
                        </div>
                    }
                    <ConditionalRender condition={props.jumbotron.asset}>
                        <Jumbotron {...props.jumbotron} />
                    </ConditionalRender>

                    {this.props.repeaterData.map((repeaterEntry, idx) => (
                        <div className="u-padding-top-med u-reset-first-child-whitespace" key={idx}>
                            <Repeater
                                type={repeaterEntry.type}
                                props={{
                                    hasSidebar: false,
                                    ...CareerLanding.getRepeaterPropsForType(repeaterEntry.type),
                                    ...repeaterEntry.props,
                                }}
                            />
                        </div>
                    ))}

                </main>

                <ConditionalRender condition={props.careerSocialLinks}>
                    <div className="l-standard-width  bg-white  u-padding-top-med  u-padding-bottom-med">
                        <div className="u-gradient-dark-blue-to-light-blue  u-gradient-angle-60  u-padding-top-large  u-padding-bottom-large">
                            <CareersSocial {...props.careerSocialLinks} />
                        </div>
                    </div>
                </ConditionalRender>
            </React.Fragment>
        );
    }
}

CareerLanding.propTypes = {
    headerProps: PropTypes.shape(PlainHeader.propTypes),
    topNav: PropTypes.shape(Nav.propTypes),
    breadcrumbLinks: PageEyebrow.propTypes.breadcrumbLinks,
    utilityNavProps: PageEyebrow.propTypes.utilityNavProps,
    main_content: PropTypes.string,
    repeaterData: PropTypes.array, // eslint-disable-line react/forbid-prop-types
    topLinks: PropTypes.arrayOf(PropTypes.shape({
        link: PropTypes.string,
        text: PropTypes.string,
    })),
    bottomCallToAction: PropTypes.shape({
        link: PropTypes.string,
        text: PropTypes.string,
    }),
    careerSocialLinks: PropTypes.shape(CareersSocial.propTypes),
    carousel: Carousel.propTypes.slides,
};

CareerLanding.defaultProps = {
    headerProps: {},
    topNav: {},
    breadcrumbLinks: [],
    utilityNavProps: {},
    main_content: '',
    topLinks: [],
    bottomCallToAction: {},
    careerSocialLinks: CareersSocial.defaultProps,
    carousel: Carousel.defaultProps.slides,
};

export default withDefaultLayoutAndGlobals(CareerLanding);
